import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router';
import AppConstants from './constants/constants.json'

Vue.config.productionTip = false;
Vue.component('questionnaire-portal', App);
Vue.config.devtools = process.env.NODE_ENV !== 'prod';

new Vue({
    el: '#app',
    data: {},
    created() {
        const appConfig = {
            settings:{
                languageId: AppConstants.defaultLanguage
            },
            components: {
                questionnairePortal: {
                    type: 'questionnairePortal',
                    settings: {
                        textColor3: 'fuchsia',
                    }
                },
            },
        };
        window.mwc.configuration.setAppConfig(appConfig);
    },
    router,
    store,
});
