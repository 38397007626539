var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isEsgRedirect
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          [
            _c(
              "div",
              [
                _vm.showLoader
                  ? _c(
                      "div",
                      [
                        _c("mds-loader", {
                          attrs: { "aria-label": "Default Loader" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showDropdown &&
                _vm.languageDropdown &&
                _vm.languageDropdown.length > 1
                  ? _c(
                      "mds-form",
                      [
                        _c(
                          "mds-select",
                          {
                            staticClass: "language-select",
                            attrs: { label: "Language" },
                            on: { change: _vm.selectLanguage },
                          },
                          _vm._l(
                            _vm.languageDropdown,
                            function (language, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: {
                                    value: language.value,
                                    selected: language.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(language.text) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("router-view", {
                  key: _vm.key,
                  staticClass: "questionnaire-portal__content",
                  attrs: { id: "page-content" },
                }),
              ],
              1
            ),
          ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }