import axios from 'axios';
import urls from '../config/env';
import { redirectToErrorPage } from '../utils/redirectToErrorPage';
import authStore from '../store/modules/authStore';
import labels from '../config/labels.json'
import AppConstants from "../constants/constants.json";

export async function getNotificationData( notificationId ){
    try{
        return await axios.get(`${urls.notificationApiUrl}/${notificationId}/detail`, {
            headers: {
                Authorization: "Bearer " + authStore.state.token
            }});
    } catch(err) {
        let errorMessage = err.response.data.message;
        if ( typeof(err.response.data.message) === 'object' ) {
            if ((err.response.data.message[0].type === 'uuid')){
                errorMessage = labels[AppConstants.defaultLanguage].invalidURLError
            }else{
                errorMessage = err.response.data.message[0].message
            }
        }
        redirectToErrorPage(errorMessage,undefined,true);
        return Promise.reject(errorMessage);
    }
}

export async function updateNotificationData(notificationId, status, params, clientId, type) {
    try {
        const request = {
            status: status
        }
        if (params) {
            request.response = params;
        }
        if(type) {
            request.type = type;
        }
        if(clientId) {
            request.clientId = clientId;
        }
        return await axios.patch(`${urls.notificationApiUrl}/${notificationId}`, request, {
            headers: {
                Authorization: "Bearer " + authStore.state.token
            }});
    } catch (error) {
        redirectToErrorPage(error.response.data.message);
        return Promise.reject(error.response.data.message);

    }
}

export async function updateNotificationStatus(notificationId, status, type){
    try {
        const request = {
            status: status
        }
        if(type) {
            request.type = type;
        }
        return await axios.patch(`${urls.notificationApiUrl}/${notificationId}/status`, request, {
            headers: {
                Authorization: "Bearer " + authStore.state.token
            }});
    } catch (error) {
        redirectToErrorPage(error.response.data.message);
        return Promise.reject(error.response.data.message);
    }
}

export async function updateQuestionnaireResponse(notificationId, type, responseId){
    try {
        const request = {
            responseId: responseId
        }
        return await axios.patch(`${urls.notificationApiUrl}/${notificationId}/response/${type}`, request, {
            headers: {
                Authorization: "Bearer " + authStore.state.token
            }});
    } catch (error) {
        redirectToErrorPage(error.response.data.message);
        return Promise.reject(error.response.data.message);
    }

}