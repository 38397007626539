var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mwc-portfolio-planner-questionnaire" },
      [
        _c("mwc-fm-questionnaire", {
          key: _vm.key,
          ref: "mwcFmQuestionnaire",
          attrs: { "mwc-id": _vm.getMwcIdForSibling(`questionnaire`) },
          domProps: {
            config: _vm.mwcQuestionnaireConfig,
            showLoadingState: true,
            readOnly: _vm.readOnly,
            questionnaireType: _vm.questionnaireType,
            questionnaireId: _vm.questionnaireId,
            dataSources: _vm.getDataSources,
          },
          on: {
            "mwc-questionnaire-answer-change": _vm.onQuestionnaireAnswerChange,
            "mwc-questionnaire-reset": _vm.onQuestionnaireReset,
            "mwc-get-questionnaire": _vm.onGettingQuestionnaire,
          },
        }),
        _c(
          "div",
          { staticClass: "mwc-portfolio-planner-questionnaire-button-wrapper" },
          [
            _vm.isQuestionnaireCompleted()
              ? _c(
                  "mds-button",
                  {
                    attrs: { variation: "primary", loading: _vm.isLoading },
                    on: { click: _vm.advanceToNextStep },
                  },
                  [_vm._v(" " + _vm._s(_vm.translate("nextButton")) + " ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }