export default {
    settings: {
        useDataManager: true,
        sourceApi: "finametrica",
        titleHidden: false
    },
    components: {
        dataManager: {
            settings: {
                additionalParameters: {
                    hostEnvironment: (process.env.NODE_ENV === 'dr' ? 'prod' : process.env.NODE_ENV)
                }
            }
        }
    }
}
