import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

const routes = [
    {
        path: '/#/FMRTQ',
        name: 'FMRTQ',
        component: () => import(/* webpackChunkName: "FMRTQ" */ '../views/FmRtq.vue'),
        props: true,
    },
    {
        path: '/#/Error',
        name: 'Error',
        component: () => import(/* webpackChunkName: "Error" */ '../views/Error.vue'),
        props: true,
    },
    {
        path: '/#/FMRESULTS',
        name: 'FMRESULTS',
        component: () => import(/* webpackChunkName: "FMRESULTS" */ '../components/fmResults.vue'),
        props: true,
    },
    {
        path: '/#/ESGRESULTS',
        name: 'ESGRESULTS',
        component: () => import(/* webpackChunkName: "ESGRESULTS" */ '../components/esgResults.vue'),
        props: true,
    },
    {
        path: '/#/CSTMRESULTS',
        name: 'CSTMRESULTS',
        component: () => import(/* webpackChunkName: "CSTMRESULTS" */ '../components/cstmResults.vue'),
        props: true,
    },
    {
        path: '/#/Settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "Settings" */ '../components/Settings.vue'),
        props: true,
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
