import router from '../router/index';

export function redirectToErrorPage(errorMessage, languageId, isThrowEror, description) {
    if (languageId) {
        router.push({
            name: 'Error',
            component: Error,
            props: true,
            params: { errorMessage: errorMessage, languageId: languageId, description },
            query: { message: errorMessage, languageId, description }
        })
    } else {
        router.push({ name: 'Error', component: Error, props: true, params: { errorMessage: errorMessage }, query: { message: errorMessage } })
    }
    if (isThrowEror) {
        throw new Error(errorMessage);
    }
}