import MwcVueBaseMixin, { MwcConfigMixin } from "mwc-vue-component-helper/src/index";
import UsageTrackingMixin from 'ec-mwc-component-helper/src/usage-tracking-mixin';

import { MdsButton, MdsButtonContainer } from '@mds/button'
import MdsPageShell from "@mds/page-shell";
import MdsSearchField from "@mds/search-field";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsFieldset from '@mds/fieldset';
import MdsForm from '@mds/form/src/form.vue';
import MdsInput from '@mds/input';
import MdsModal from '@mds/modal';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import MdsLoader from '@mds/loader';
import MdsSelect from '@mds/select';

import { mapState, mapGetters, mapActions } from 'vuex';

import labels from './config/labels.json';
import components from './config/components';
import { eventBus } from './event-bus.js';
import FmRtq from './views/FmRtq.vue';
import { getAuthenticate } from "./service/authenticationService";
import AppConstants from "./constants/constants.json";
import { getRandomNumber, getURLParameter } from './helper';
import { redirectToErrorPage } from "./utils/redirectToErrorPage";
import languages from "./constants/languages.json";
import { getNotificationData } from "./service/notificationApiService";
import router from './router';
import urls from './config/env';

export default {
    name: 'questionnaire-portal',
    mixins: [MwcVueBaseMixin, MwcConfigMixin, UsageTrackingMixin],
    data() {
        return {
            defaultConfig: {
                type: 'questionnaire-portal',
                intlNamespace: this.mwcId,
                components,
                settings: {
                    textColor1: 'blue',
                    textColor2: 'blue',
                    textColor3: 'blue',
                },
                labels,
            },
            eventBus,
            store: {
                state: {
                    answers: [],
                    results: []
                }
            },
            loading: false,
            isError: false,
            languageDropdown: [],
            key: 0,
            showDropdown: false,
            showLoader: true,
            isEsgRedirect: true,
            region: '',
            countryToRegion: {
              'AU': 'AU',
              'CA': 'CA',
              'IN': 'IN',
              'NZ': 'NZ',
              'GB': 'UK',
              'US': 'USA'
            },
            language: '',
        };
    },
    components: {
        MdsButton,
        MdsButtonContainer,
        MdsFieldset,
        MdsForm,
        MdsInput,
        MdsListGroup,
        MdsListGroupItem,
        MdsLoader,
        MdsModal,
        MdsNotification,
        MdsNotificationContainer,
        MdsPageShell,
        MdsSearchField,
        FmRtq,
        MdsSelect,
    },
    async mounted() {
        this.$root.$on("showDropdown", () => {
            this.showDropdown = true;
        });
        this.$root.$on("hideDropdown", () => {
            this.showDropdown = false;
        });
        // this.$nextTick(async function() {
        try {
            const url = new URL(window.location.href.replace('#/', ''));
            const notificationIdFromURL = getURLParameter("id");
            const riskToleranceViewFromURL = getURLParameter("riskToleranceView");
            /**
            * SPM-5991: Temporary code to redirect to ESG Portal
            */
            const route = this.getURLPath();
            if (route === AppConstants.esgQuestionnaire) {
                window.location = `${urls.esgPortalUrl}?id=${notificationIdFromURL}`;
                return;
            }
            this.isEsgRedirect = false;
            // end of temporary code
            if (this.checkIfUrlIsValid(url)) {
                const response = await getAuthenticate();
                await this.updateToken(response.data.access_token);
                const notificationDetails = await getNotificationData(notificationIdFromURL);
                await this.$store.dispatch('productStore/updateNotificationDetails', notificationDetails.data);
                this.updateFMRTQData();
                const country = notificationDetails.data.language.split("-")[1];
                this.language = notificationDetails.data.language;
                this.updateCurrentNotificationStatus(notificationDetails.data.status);
                this.setLanguageDropdown(country.toUpperCase());
                this.setAppLanguage(this.language);
                this.region = this.getRegionFromCountry(country);
            } else {
                this.isError = true;
                this.showLoader = false;
                const message = getURLParameter('message');
                const languageId = getURLParameter('languageId');
                const description = getURLParameter('description');
                if (message) {
                    redirectToErrorPage(message, languageId, false, description);
                } else {
                    redirectToErrorPage(labels[AppConstants.defaultLanguage].invalidURLError);
                }
                return;
            }
            this.registerTokenSource({ tokenSource: 'uim', env: this.settings.env }); // for mwc-fm-questionnaire (UIM)
            this.setComponentLoadWaitsOn([this.getMwcIdForChild('questionnaireWrapper')]);
            if (url.pathname !== '/') {
                const routeName = url.pathname.substring(1);
                if (this.notificationDetails && this.notificationDetails.type.length === 1 && this.notificationDetails.type[0] === AppConstants.QuestionnaireSettings) {
                    this.$router.push({
                        name: 'Settings',
                        params: {
                            eventBus: this.eventBus,
                            parentMwcId: this.mwcId,
                            store: this.store
                        },
                        query: {
                            id: notificationIdFromURL
                        }
                    });
                } else if ((routeName === 'FMRESULTS' || routeName === 'CSTMRESULTS' || routeName === 'ESGRESULTS') && !this.fmrtqClientId && !this.fmrtqResponseId) {
                    redirectToErrorPage(labels[this.language].questionnaireNotCompleted, this.language);
                } else if (!this.isError) {
                    this.$router.push({
                        name: routeName,
                        params: {
                            eventBus: this.eventBus,
                            parentMwcId: this.mwcId,
                            store: this.store,
                        },
                        query: {
                            id: notificationIdFromURL,
                            riskToleranceView: String(riskToleranceViewFromURL).toLowerCase() === 'true',
                            region: this.region,
                            lang: this.language,
                        },
                    });
                }
                this.showLoader = false;
            }
        } catch (e) {
            this.showLoader = false;
        }
        // });
    },
    computed: {
        ...mapState(`authStore`, [`token`, `notificationToken`]),
        ...mapGetters(`authStore`, [
            `token`,
            `notificationToken`,
        ]),
        ...mapState(`productStore`, [`currentNotificationStatus`, `language`, `notificationDetails`]),
        ...mapGetters(`productStore`, [
            `currentNotificationStatus`,
            `language`,
            `notificationDetails`
        ]),
        ...mapState(`rtqStore`, [`fmrtqClientId`, `fmrtqResponseId`]),
        ...mapGetters(`rtqStore`, [
            `fmrtqClientId`,
            `fmrtqResponseId`
        ]),
    },
    methods: {
        ...mapActions(`authStore`, [
            `updateToken`,
        ]),
        ...mapActions(`productStore`, [
            `updateCurrentNotificationStatus`,
            `updateLanguage`,
            `updateNotificationDetails`
        ]),
        ...mapActions(`rtqStore`, [
            `updateFMRTQClientId`,
            `updateFMRTQResponseId`
        ]),
        getURLPath() {
            const url = new URL(window.location.href.replace('#/', ''));
            return url.pathname.substring(1);
        },
        updateFMRTQData() {
            if (this.notificationDetails.clientId) {
                this.updateFMRTQClientId(this.notificationDetails.clientId);
            }
            const { response } = this.notificationDetails;
            if (response) {
                const { FM10S30, FM25V30, ESGQ2V1 } = response;
                const result = FM10S30 || FM25V30 || ESGQ2V1
                if (result) {
                    this.updateFMRTQResponseId(result.responseId);
                }
            }
        },
        setAuthenticate() {
            return {
                apiGatewayToken: `Bearer ${this.token}`
            };
        },
        registerTokenSource(options) {
            options = options || {};
            const apiTokenSource = {
                name: options.tokenSource || 'us',
                decorateRequest: (request) => {
                    const requestOptions = request.requestOptions || {};
                    requestOptions.headers = request.requestOptions.headers || {};
                    requestOptions.headers.Authorization = 'Bearer ' + this.token;
                    return requestOptions;
                },
                refreshToken: (callback) => {
                    const apiTokens = this.setAuthenticate();
                    callback(null, apiTokens.apiGatewayToken);
                },
            }
            window.mwc.dataAccess.registerTokenSource(apiTokenSource);
        },
        setLanguageDropdown(country) {
            this.languageDropdown = languages[country];
        },
        selectLanguage(event) {
            const value = event?.target?.value || event;
            this.setAppLanguage(value);
        },
        setAppLanguage(language) {
            window.mwc.configuration.setAppConfig({
                settings: {
                    languageId: language
                }
            });
            const oldSelectedIangIndex = this.languageDropdown.findIndex(e => e.value === this.language);
            const newSelectedlangIndex = this.languageDropdown.findIndex(e => e.value === language);
            if (oldSelectedIangIndex >= 0) {
                this.languageDropdown[oldSelectedIangIndex].selected = false;
            }
            if (newSelectedlangIndex >= 0) {
                this.languageDropdown[newSelectedlangIndex].selected = true;
            }
            this.updateLanguage(language);

            /*uncomment below code to use on local
                window.mwc.configuration.setAppConfig({
                settings: {
                    languageId: event.target.value
                }
                });
                this.languageDropdown.find(e => e.value === this.language).selected = false;
                this.updateLanguage(event.target.value);
                this.languageDropdown.find(e => e.value === event.target.value).selected = true;
            */
            this.key += getRandomNumber();
        },
        checkIfUrlIsValid(url) {
            const routeName = url.pathname.substr(1);
            return router.options.routes.find((route) => {
                return route.name === routeName;
            }) && getURLParameter("id");
        },
        getRegionFromCountry(country) {
          let region = this.countryToRegion[country];
          if (region === undefined) {
            region = 'USA';
          }
          return region;
        }
    }
};
