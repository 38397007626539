const authStore = {
    state: {
        token: null,
        notificationToken: null,
    },
    mutations: {
        updateToken(state, data) {
            state.token = data;
        },
        updateNotificationToken(state, data) {
            state.notificationToken = data;
        }
    },
    getters: {
        token: (state) => {
            return state.token;
        },
        notificationToken: (state) => {
            return state.notificationToken;
        }

    },
    actions: {
        updateToken(context, payload) {
            context.commit(`updateToken`, payload)
        },
        updateNotificationToken(context, payload) {
            context.commit(`updateNotificationToken`, payload)
        }
    }
}

export default authStore;
