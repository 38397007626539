import axios from 'axios';
import urls from '../config/env';
import { redirectToErrorPage } from '../utils/redirectToErrorPage';
import authStore from '../store/modules/authStore';

export const commonHeaders = {
    "Content-Type": "application/json",
    "accept-language": "en-US",
}

export async function generateClientId(externalId, headerParameters){
    try{
        return await axios.post(urls.fmrtqApiUrl + "/v1/clients/generate-id", externalId, {
            headers: headerParameters
          });
    } catch(error){
        redirectToErrorPage(error.response.data.message);
        return Promise.reject(error.response.data.message);

    }
}

export async function generateRiskResponseId(data, headerParameters){
    try{
        return await axios.post(urls.fmrtqApiUrl + "/v2/clients/risks/questionnaires", data, {
            headers: {
                ...commonHeaders,
                ...headerParameters
            }
          });
    } catch(error){
        redirectToErrorPage(error.response.data.message);
        return Promise.reject(error.response.data.message);

    }
}

export async function generateKYCResponseId(data, headerParameters){
    try{
        return await axios.post(urls.fmrtqApiUrl + "/v1/clients/kyc/questionnaires", data, {
            headers: {
                ...commonHeaders,
                ...headerParameters
            }
          });
    } catch(error){
        redirectToErrorPage(error.response.data.message);
        return Promise.reject(error.response.data.message);

    }
}

export async function generateExperienceResponseId(data, headerParameters){
    try{
        return await axios.post(urls.fmrtqApiUrl + "/v1/clients/experience/questionnaires", data, {
            headers: {
                ...commonHeaders,
                ...headerParameters
            }
          });
    } catch(error){
        redirectToErrorPage(error.response.data.message);
        return Promise.reject(error.response.data.message);
    }
}

export async function logError(data){
    try{
        await axios.post(urls.questionnaireUrl + "/portal/logs", data,{
            headers: {
                Authorization: "Bearer " + authStore.state.token
            }
        });
    } catch(error){
        console.error(error);
    }
}