var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "thank-you-msg" },
      [
        _vm._m(0),
        _c("mds-empty-state", {
          attrs: {
            title: _vm.labels[this.languageId].submissionSuccess,
            message: _vm.labels[this.languageId].submissionSuccessNote,
            role: "status",
            "aria-live": "polite",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "img-container" }, [
      _c("img", {
        attrs: { src: require("../assets/tick-image.png"), alt: "Tick Image" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }