export default (riskQuestionnaireType)=>[{
    "id": 1,
    "title": "",
    "active": true,
    "component": "Welcome"
},
{
    "id": 2,
    "title": "Risk Tolerance",
    "active": false,
    "component": riskQuestionnaireType
},
{
    "id": 3,
    "title": "Thank You",
    "active": false,
    "component": "thankyou"
}
]
