import axios from 'axios';
import urls from '../config/env';
import { redirectToErrorPage } from '../utils/redirectToErrorPage';

export async function getAuthenticate() {
    try {
        return await axios.get(`${urls.questionnaireUrl}/gettoken/fm`, {
            headers: {
                'x-api-key': urls.apiKey
            }
        });
    } catch (error) {
      redirectToErrorPage(error.response.data.message, undefined, true);
      return Promise.reject(error.response.data.message);
    }
}