<template>
    <div>
        <div class="thank-you-msg">
            <div class="img-container">
                <img src="../assets/tick-image.png" alt="Tick Image">
            </div>
            <mds-empty-state
                :title="labels[this.languageId].submissionSuccess"
                :message="labels[this.languageId].submissionSuccessNote"
                role="status"
                aria-live="polite"
            ></mds-empty-state>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import MdsEmptyState from "@mds/empty-state";
import { mapState, mapGetters, mapActions } from "vuex";
import AppConstants from '../constants/constants.json';
import labels from '../config/labels.json';

const ThankYou = Vue.extend({
    name: "thank-you",
    props:{
    },
    components: {
        MdsEmptyState
    },
    data: function () {
        return {
            labels:labels,
            languageId: ''
        };
    },
    computed: {
        ...mapState(`productStore`, [`productRTQScore`, `currentNotificationStatus`, `language`]),
        ...mapGetters(`productStore`, [
            `productRTQScore`,
            `currentNotificationStatus`,
            `language`
        ]),
        ...mapState(`authStore`, [`notificationToken`]),
        ...mapGetters(`authStore`, [
            `notificationToken`
        ]),
    },
    methods: {
        ...mapActions(`productStore`, [
            `updateProductRTQScore`,
            `updateCurrentNotificationStatus`,
            `updateLanguage`
        ]),
    },
    async beforeMount() {
        this.languageId = this.language;
    },
    async mounted() {
        if( this.currentNotificationStatus === AppConstants.requestInProgress ){
            this.updateCurrentNotificationStatus(AppConstants.requestCompleted);
        }
    }
});

export default ThankYou;
</script>

<style lang="scss">
@import "@mds/typography";

.thank-you-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;

    position: absolute;
    width: 40%;
    height: 72px;
    left: 30%;
    top: 45%;

    .mds-empty-state__pp {
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
    }

    .mds-empty-state__pp .mds-empty-state__title__pp {
        @include mds-level-3-heading();
        height: 34px;
        text-align: center;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .mds-empty-state__pp .mds-empty-state__message__pp {
        @include mds-body-text-l;
        display: flex;
        height: 26px;
        text-align: center;
        flex-direction: column;
        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }

}
</style>

