import constants from "../../constants/constants.json";

const productStore = {
    state: {
        productRTQScore: null,
        currentNotificationStatus: null,
        language: constants.defaultLanguage,
        notificationDetails: null
    },
    mutations: {
        rtqScore(state, data) {
            state.productRTQScore = data;
        },
        updateCurrentNotificationStatus(state, data) {
            state.currentNotificationStatus = data;
        },
        updateLanguage(state, data) {
            state.language = data;
        },
        updateNotificationDetails(state, data) {
            state.notificationDetails = data;
        }
    },
    getters: {
        productRTQScore: (state) => {
            return state.productRTQScore;
        },
        currentNotificationStatus: (state) => {
            return state.currentNotificationStatus;
        },
        language: (state) => {
            return state.language;
        },
        notificationDetails: (state) => {
            return state.notificationDetails;
        }

    },
    actions: {
        updateProductRTQScore(context, payload) {
            context.commit(`rtqScore`, payload)
        },
        updateCurrentNotificationStatus(context, payload) {
            context.commit(`updateCurrentNotificationStatus`, payload)
        },
        updateLanguage(context, payload) {
            context.commit(`updateLanguage`, payload);
        },
        updateNotificationDetails(context, payload) {
            context.commit(`updateNotificationDetails`, payload);
        }
    }
}

export default productStore;
