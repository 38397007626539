import Vue from "vue";
import Vuex from "vuex";
import { deepCopy, importModules } from "@/helper";
// eslint-disable-next-line
const context = require.context("./modules", true, /\.js$/);

function setModuleDefaults(module) {
    // modules default to namespaced = true, but this can be overwritten.
    return Object.assign({ namespaced: true }, module);
}

// handle fetching non-reactive data from the store
function clone({ state }, paths) {
    // expect a dot-separated string
    const keys = paths.split(".");
    // keep track of our search path
    const tracer = [];
    // find the requested value
    const item = keys.reduce((next, key) => {
        // the first key should point to a module in the state
        if (next) {
            tracer.push(key);
            return next[key];
        }else{
            return null;
        }
}, state);
    // return a promise for consistency across store actions
    if (item) {
        // return a clone of item
        return Promise.resolve(deepCopy(item));
    } else {
        const missing = tracer.pop();
        const src = tracer.join(".");
        // this should be informative
        return Promise.reject(`could not find "${missing}" in ${src}`);
    }
}

const modules = importModules(context, (key, item) => {
    // extract the module subdirectory name
    const name = key.replace(/^.*(\\|\/|\\:)|\..*$/g, "");
    // assign module store defaults
    return { [name]: setModuleDefaults(item.default) };
});

Vue.use(Vuex);

export default new Vuex.Store({
    // throw an error if an attempt is made to mutate the store directly
    // we should always run in strict mode
    // https://vuex.vuejs.org/en/strict.html#development-vs-production
    strict: process.env.NODE_ENV !== "test",
    // "global" actions
    actions: { clone },
    state: {},
    // compose API modules
    modules
});

// ask Vue to ignore "unknown custom element" warnings for web-components
// defined outside the Vue application
Vue.config.ignoredElements = ["card", /pop-.*/, /mwc-.*/];
