const rtqStore = {
    state: {
        fmrtqClientId: null,
        fmrtqResponseId: null,
        calculatedScore: null,
    },
    mutations: {
        updateFMRTQClientId(state, data) {
            state.fmrtqClientId = data;
        },
        updateFMRTQResponseId(state, data) {
            state.fmrtqResponseId = data;
        },
        updateFMRTQCalculatedScore(state, data){
            state.calculatedScore = data;
        }
    },
    getters: {
        fmrtqClientId: (state) => {
            return state.fmrtqClientId;
        },
        
        fmrtqResponseId: (state) => {
            return state.fmrtqResponseId;
        },

        calculatedScore: (state) => {
            return state.calculatedScore;
        },

    },
    actions: {
        updateFMRTQClientId(context, payload) {
            context.commit(`updateFMRTQClientId`, payload)
        },
        updateFMRTQResponseId(context, payload) {
            context.commit(`updateFMRTQResponseId`, payload)
        },
        updateFMRTQCalculatedScore(context, payload){
            context.commit('updateFMRTQCalculatedScore', payload)
        }
    }
}

export default rtqStore;
