/**
 * Fetches the value of the URL parameter from window.location.hash or window.location.search
 * @param  {string} name The name of the URL parameter
 * @return {string} The value of the parameter
 */
function getURLParameter(name) {
    return decodeURIComponent((new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`)
    .exec(location.hash) || [null, ''])[1].replace(/\+/g, '%20')) || getQueryString()[name] || null;
}

function getQueryString() {
    const res = {};
    let key = false;
    let itm = null;
    // get the query string without the ?
    const qs = location.search.substring(1);
    // check for the key as an argument
    if (arguments.length > 0 && arguments[0].length > 1) {
        key = arguments[0];
    }
    // make a regex pattern to grab key/value
    var pattern = /([^&=]+)=([^&]*)/g;
    // loop the items in the query string, either
    // find a match to the argument, or build an object
    // with key/value pairs
    itm = pattern.exec(qs); // get the first match
    while (itm) {
        if (key !== false && decodeURIComponent(itm[1]) === String(key)) {
            return decodeURIComponent(itm[2]);
        }
        else if (key === false) {
            res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
        }
        itm = pattern.exec(qs); // get the next match
    }

    return key === false ? res : null;
}

/**
 * Adds or updates the URL parameter to the URI string passed and returns the updated URI
 * @param  {string} uri The URL / URI string to which the parameter needs to be updated
 * @param  {string} key The parameter's name
 * @param  {any} value The new value of the parameter
 * @return {string} The updated URL/URI string
 */

/**     
function updateQueryStringParameter(uri, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
    const separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, `$1${key}=${value}$2`);
    } else {
        return `${uri}${separator}${key}=${value}`;
    }
} 
*/

function mergeCustomizer(a, b) { // Passed to lodash mergeWith function so that we can merge object together while replacing arrays
    if (b instanceof Array) {
        return b;
    }
    return undefined;
}

function parseJwt(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

function importModules(context, schema) {
    return context.keys().reduce((defs, key) => {
        return Object.assign(defs, schema(key, context(key)));
    }, {});
}

function deepCopy(obj) {
    // This will fail with any values that are not included in the JSON spec.
    // i.e. dates, functions, undefined values, etc. will not be restored to
    // object representations (dates are strings,) or else ignored completely.
    // This shouldn't be an issue in the majority of cases, since most of our
    // data is already JSON, but something to look out for.
    if (!obj) {
        return "";
    } else if (obj.constructor.name === "Date") {
        return new Date(obj);
    } else {
        return JSON.parse(JSON.stringify(obj));
    }
}

function getRandomNumber(){
    const crypto = window.crypto || window.msCrypto;
    const unsignedInt = new Uint32Array(1)
    const randomNumber = crypto.getRandomValues(unsignedInt);
    return randomNumber[0];
}

export {
    deepCopy,
    getURLParameter,
    importModules,
    mergeCustomizer,
    parseJwt,
    getRandomNumber,
    // updateUrlParamAndReload,
}
