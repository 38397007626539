import MwcVueHelperMixin from 'mwc-vue-component-helper';

export default {
    mixins: [MwcVueHelperMixin],
    computed: {
        parentMwcId() {
            if (!this.mwcId) {
                this.logger.warn(this.$options.name, `mwcId property is missing from ${this.$options.name} component`);
                return '';
            }

            const mwcIdArray = this.mwcId.split('.');
            mwcIdArray.pop();
            return mwcIdArray.join('.');
        }
    },
    methods: {
        getMwcIdForSibling(identifier) {
            return [this.parentMwcId, identifier].filter(x => x).join('.');
        },
    },
};
